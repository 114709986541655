import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from '../../services/axios';
import { useAuth0 } from '@auth0/auth0-react';

function UploadSection({ onUploadStart, updateUploadProgress, onUploadSuccess, onUploadError, session_id }) {
  const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
  const [uploadProgress, setUploadProgress] = useState({}); // Track progress for each file

  const extractMetadata = async (file) => {
    console.time('Metadata Extraction Time'); // Debug start
    const videoElement = document.createElement('video');
    const objectUrl = URL.createObjectURL(file);
  
    return new Promise((resolve) => {
      videoElement.src = objectUrl;
      videoElement.onloadedmetadata = () => {
        const metadata = {
          name: file.name,
          size: file.size,
          type: file.type,
          duration: videoElement.duration,
          width: videoElement.videoWidth,
          height: videoElement.videoHeight,
        };
        URL.revokeObjectURL(objectUrl); // Cleanup
        console.timeEnd('Metadata Extraction Time'); // Debug end
        resolve(metadata);
      };
    });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    console.log("Starting upload process for files:", acceptedFiles);

    const uploadFiles = acceptedFiles.map(async (file) => {
      try {
        console.time('Total Upload Time'); // Track total upload time per file
        
        console.time('Extract Metadata Time');
        const fileMetadata = await extractMetadata(file);
        console.timeEnd('Extract Metadata Time');

        console.log('Extracted Metadata:', fileMetadata);

        const params = {
          fileName: file.name,
          fileType: file.type,
          sessionId: session_id,
          fileMetadata: fileMetadata,
        };

        console.time('Get Presigned URL'); // Track presigned URL retrieval
        let presignedResponse;
        if (isAuthenticated && user) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          presignedResponse = await axios.get('/videos/upload-S3-video', { headers, params });
        } else {
          presignedResponse = await axios.get('/videos/upload-S3-video', { params });
        }
        console.timeEnd('Get Presigned URL');

        const presignedUrl = presignedResponse.data.url;
        const fileId = presignedResponse.data.id;
        
        onUploadStart(file, fileId, fileMetadata);

        const options = {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress((prevProgress) => ({
              ...prevProgress,
              [fileId]: progress,
            }));
            updateUploadProgress(progress, fileId);
          },
          retry: true,
          retryLimit: 3,
          retryDelay: 2000,
        };

        console.time('File Upload Time'); // Track file upload time
        await axios.put(presignedUrl, file, options);
        console.timeEnd('File Upload Time');

        onUploadSuccess(); // Notify parent component that the upload was successful
        console.timeEnd('Total Upload Time'); // End tracking total upload time
      } catch (error) {
        console.error('Error uploading file:', error);
        if (onUploadError) {
          onUploadError(error);
        }
      }
    });

    await Promise.all(uploadFiles);
  }, [isAuthenticated, getAccessTokenSilently, user, onUploadStart, onUploadSuccess, onUploadError, session_id, updateUploadProgress]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: true });

  return (
    <div className="container mt-4">
      <h2>Upload a Video</h2>
      <div {...getRootProps()} className="dropzone">
        <input {...getInputProps()} />
        {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>}
      </div>
    </div>
  );
}

export default UploadSection;
