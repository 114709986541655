import React, { useState, useEffect, useRef } from 'react';
import { FaDownload, FaTrash, FaEllipsisV, FaInfoCircle, FaPlayCircle, FaCog, FaSpinner, FaPencilAlt } from 'react-icons/fa';
import './VideoListItem.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DOMPurify from 'dompurify';
import { formatDate, formatDuration} from '../../services/utilityService'

// Import the API service functions
import { downloadVideo, processVideo, pollVideoProgress, updateVideoHashtags, getVideoProgress } from '../../services/apiService';

function VideoListItem({ video, isPlaying, onVideoPlay, uploadProgress, onDelete, onEdit }) {
  const [showOptions, setShowOptions] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [processingError, setProcessingError] = useState(false);
  const [progress, setProgress] = useState(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [hashtags, setHashtags] = useState(video.hashtags || '');
  const [isEditingHashtags, setIsEditingHashtags] = useState(false);
  const { metadata } = video;
  const videoRef = useRef(null);
  const optionsRef = useRef(null);
  const infoRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
      if (infoRef.current && !infoRef.current.contains(event.target)) {
        setShowInfo(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, []);

  const handleDownloadClick = async (event) => {
    event.stopPropagation();
    setShowOptions(false);
    try {
      await downloadVideo(video.url, video.original_name);
    } catch (error) {
      toast.error('Failed to start download.');
    }
  };

  const handleDeleteClick = async (event) => {
    event.stopPropagation();
    setShowOptions(false);
    onDelete(video.id)
    //await deleteVideo(video.id);
  };

  const handleOptionsClick = (event) => {
    event.stopPropagation();
    setShowOptions(!showOptions);
  };

  const handleInfoClick = (event) => {
    event.stopPropagation();
    setShowInfo(!showInfo);
  };

  const handleEditClick = (event) => {
    event.stopPropagation();
    setShowOptions(false);
    onEdit(video);  // Call the onEdit callback
  };

  const handlePlayClick = (event) => {
    event.stopPropagation();
    if (video.status === 'Uploading...') return;
    if (!videoLoaded) {
      setVideoLoaded(true);
    }
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        onVideoPlay(video.id);
      }
    }
  };

  const handleProcessClick = async (event) => {
    event.stopPropagation();
    setProcessingError(false);
    setShowOptions(false);
    try {
      setProgress(1);
      await processVideo(video.id);

      const pollProgress = async () => {
        try {
          const progressData = await getVideoProgress(video.id);
          setProgress(progressData.percent_complete);

          if (progressData.percent_complete < 100) {
            setTimeout(pollProgress, 1000);
          } else {
            setProgress(null);
          }
        } catch (error) {
          console.error('Error fetching progress:', error);
          toast.error('Failed to fetch progress.');
        }
      };

      pollProgress();
    } catch (error) {
      setProcessingError(true);
      toast.error('Failed to start processing.');
    }
  };

  const validateHashtags = (tags) => {
    const regex = /^(#\w+)+$/;
    return regex.test(tags);
  };

  const handleSaveHashtags = async () => {
    if (!validateHashtags(hashtags)) {
      toast.error('Invalid hashtags. Only letters, numbers, underscores, and # are allowed.');
      return;
    }

    const sanitizedHashtags = DOMPurify.sanitize(hashtags);
    setIsEditingHashtags(false);
    video.hashtags = sanitizedHashtags;

    try {
      await updateVideoHashtags(video.id, sanitizedHashtags);
    } catch (error) {
      toast.error('Failed to save hashtags.');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSaveHashtags();
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isPlaying]);

  return (
    <li className="video-item">
      <div className="video-container" onClick={handlePlayClick}>
        <video ref={videoRef} width="100%" height="100%" controls={false}>
          {videoLoaded && <source src={video.url} type="video/mp4" />}
          Your browser does not support the video tag.
        </video>
        {video.status === 'Uploading...' ? (
          <FaSpinner className="loading-spinner" />
        ) : (
          !isPlaying && <FaPlayCircle className="play-button" />
        )}
      </div>
      <FaEllipsisV onClick={handleOptionsClick} className="options-icon" />
      {showOptions && (
        <div className="options-dropdown" ref={optionsRef}>
          <button onClick={handleDownloadClick} className={`download-button ${video.status === 'Uploading...' ? 'disabled' : ''}`} disabled={video.status === 'Uploading...'}>
            <FaDownload /> Download
          </button>
          <button onClick={handleDeleteClick} className={`delete-button ${video.status === 'Uploading...' ? 'disabled' : ''}`} disabled={video.status === 'Uploading...'}>
            <FaTrash /> Delete
          </button>
          <button onClick={handleProcessClick} className={`process-button ${video.status === 'Uploading...' ? 'disabled' : ''}`} disabled={video.status === 'Uploading...'}>
            <FaCog /> Process
          </button>
          <button onClick={handleEditClick} className={`process-button ${video.status === 'Uploading...' ? 'disabled' : ''}`} disabled={video.status === 'Uploading...'}>
            <FaPencilAlt /> Edit
          </button>
        </div>
      )}
      <FaInfoCircle onClick={handleInfoClick} className="info-icon" />
      {showInfo && (
        <div className="info-dropdown" ref={infoRef}>
          <p>{`Name: ${video.original_name}`}</p>
          <p>{metadata?.upload_date ? `Date: ${formatDate(metadata.upload_date)}` : 'N/A'}</p>
          <p>{metadata?.duration ? `Duration: ${formatDuration(metadata.duration)}` : 'N/A'}</p>
          <p>{metadata ? `Resolution: ${metadata.width}x${metadata.height}` : 'N/A'}</p>
          <p>{metadata ? `Size: ${(metadata.size / (1024 ** 3)).toFixed(2)} GB` : 'N/A'}</p>
          <p>{video?.status ? `Status: ${video.status}` : 'N/A'}</p>
        </div>
      )}
      <div className="video-details">
        <div className="video-info">
          <p className="video-name">{video.original_name}</p>
          <p className="video-date">{metadata?.upload_date ? formatDate(metadata.upload_date) : 'N/A'}</p>
          <p className="video-duration">{metadata?.duration ? formatDuration(metadata.duration) : 'N/A'}</p>
          {isEditingHashtags ? (
            <div className="edit-hashtags">
              <input type="text" value={hashtags} onChange={(e) => setHashtags(e.target.value)} onBlur={handleSaveHashtags} onKeyDown={handleKeyDown} onClick={(e) => e.stopPropagation()} />
            </div>
          ) : (
            <p className="video-hashtags" onClick={(e) => { e.stopPropagation(); setIsEditingHashtags(true); }}>{hashtags || 'Add hashtags'}</p>
          )}
        </div>
        <div className="icons">
          <FaEllipsisV onClick={handleOptionsClick} className="options-icon" />
          <FaInfoCircle onClick={handleInfoClick} className="info-icon" />
        </div>
      </div>
      {processingError && <div className="error-flag">Processing Failed</div>}
      {progress !== null && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progress}%` }}></div>
        </div>
      )}
      {/* Upload Progress Bar */}
      {video.status === 'Uploading...' && (
        <div className="progress-bar">
          <div className="progress" style={{ width: `${video.progress}%` }}></div>
        </div>
      )}
    </li>
  );
}

export default VideoListItem;
