import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FriendGroup from '../FriendGroup/FriendGroup';

const GroupList = ({
  groups,
  user,
  showGroups,
  handleAddFriendToGroup,
  handleRemoveFriendFromGroup,
  handleMakeAdmin,
  handleLeaveGroup,
}) => {
  if (!showGroups) return null;

  return (
    <Row>
      {groups.map((group) => (
        <Col md={6} key={group.id}>
          <FriendGroup
            group={group}
            user={user}
            handleAddFriendToGroup={handleAddFriendToGroup}
            handleRemoveFriendFromGroup={handleRemoveFriendFromGroup}
            handleMakeAdmin={handleMakeAdmin}
            handleLeaveGroup={handleLeaveGroup}
          />
        </Col>
      ))}
    </Row>
  );
};

export default GroupList;
