import axios from './axios';

// Fetch user session (either protected or standard) based on authentication state
export const fetchSessionId = async (isAuthenticated, getAccessTokenSilently) => {
    let sessionId;
    
    if (isAuthenticated) {
        const existingProtectedSessionId = localStorage.getItem('protected_session_id');
        if (existingProtectedSessionId) {
            return existingProtectedSessionId;
        } else {
            const token = await getAccessTokenSilently({ audience: "https://api.cheerclip.com" });
            const headers = { Authorization: `Bearer ${token}` };
            const response = await axios.get('/user/protected-session', { headers });
            sessionId = response.data.session_id;
            localStorage.setItem('protected_session_id', sessionId);
        }
    } else {
        const existingSessionId = localStorage.getItem('session_id');
        if (existingSessionId) {
            return existingSessionId;
        } else {
            const response = await axios.get('/user/session');
            sessionId = response.data.session_id;
            localStorage.setItem('session_id', sessionId);
        }
    }

    return sessionId;
};

// Fetch videos based on session or user authentication
export const fetchUserVideos = async (isAuthenticated, user, session_id, getAccessTokenSilently) => {
    let response;
    if (isAuthenticated && user) {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        response = await axios.get('/videos/user-videos', { headers });
    } else {
        response = await axios.get('/videos/session-videos', { params: { sessionId: session_id } });
    }
    return response.data.videos;
};

// Upload a video
export const uploadVideo = async (file, session_id, metadata, getAccessTokenSilently) => {
    const token = await getAccessTokenSilently();
    const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' };
    const formData = new FormData();
    formData.append('file', file);
    formData.append('session_id', session_id);
    formData.append('metadata', JSON.stringify(metadata));

    const response = await axios.post('/videos/upload', formData, { headers });
    return response.data;
};


// Cut video by ID
export const cutVideo = async (videoId, cuts, session_id) => {
    const payload = {
      videoId,
      cuts,
      session_id
    };
  
    try {
      const endpoint = "/videos/cutVideo";
      const response = await axios.post(endpoint, payload);
      return response.data; // You can return the response data if needed
    } catch (error) {
      console.error("Error cutting video:", error);
      throw error; // Re-throw the error for further handling
    }
  };

// Fetch user information
export const fetchUserInformation = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/user/user-information', { headers });
    return response.data.user_info;
};

// Fetch user options
export const fetchUserOptions = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/user/user-options', { headers });
    return response.data.user_options;
};

// Fetch user settings
export const fetchUserSettings = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/user/user-settings', { headers });
    return response.data.user_settings;
};

// Fetch user details
export const fetchUserDetails = async (token) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get('/user/user-details', { headers });
    return response.data.user_details;
};

// Check if nickname exists
export const checkNicknameAvailability = async (token, nickname) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.get(`/user/check-user-existence?nickname=${nickname}`, { headers });
    return response.data;
};

// Update user information
export const updateUserInformation = async (token, userInfo) => {
    const headers = { Authorization: `Bearer ${token}` };
    const response = await axios.put('/user/update-user-information', userInfo, { headers });
    return response.data;
};

// Update profile picture
export const updateProfilePicture = async (token, profilePicture) => {
    const headers = { Authorization: `Bearer ${token}` };
    const formData = new FormData();
    formData.append('profilePicture', profilePicture);
    const response = await axios.post('/user/update-profile-picture', formData, { headers });
    return response.data;
};

// Function to get the presigned URL for file upload
export const getPresignedUrl = async (file, session_id, metadata, getAccessTokenSilently, isAuthenticated, user) => {
    const params = {
      fileName: file.name,
      fileType: file.type,
      sessionId: session_id,
      fileMetadata: metadata, // Add the metadata to the params
    };
  
    let response;
    try {
      if (isAuthenticated && user) {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        response = await axios.get('/videos/upload-S3-video', { headers, params });
      } else {
        response = await axios.get('/videos/upload-S3-video', { params });
      }
  
      return response.data; // Should return presigned URL and any additional data
    } catch (error) {
      console.error('Error fetching presigned URL:', error);
      throw error;
    }
  };

  // Function to send metadata to the backend
export const sendVideoMetadata = async (fileId, metadata, sessionId, getAccessTokenSilently, isAuthenticated) => {
    const payload = {
      fileId,
      metadata,
      sessionId,
    };
  
    // Add authentication headers if required
    let response;
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      response = await axios.post('/videos/metadata', payload, { headers });
    } else {
      response = await axios.post('/videos/metadata', payload);
    }
  
    return response.data;
  };
  
  // Upload the file to the S3 presigned URL
  export const uploadFileToS3 = async (presignedUrl, file, updateUploadProgress, fileId) => {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': file.type,
      },
      onUploadProgress: (progressEvent) => {
        const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        updateUploadProgress(progress, fileId);
      },
    };
  
    await axios.put(presignedUrl, file, options);
  };

  // Download video by URL
export const downloadVideo = async (videoUrl, fileName) => {
  try {
    const response = await fetch(videoUrl, { method: 'GET' });
    if (!response.ok) throw new Error('Failed to fetch the video file');
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error during download:', error);
    throw error;
  }
};

// Delete video by ID
export const deleteVideo = async (videoId) => {
  try {
    const response = await axios.delete(`/videos/deleteVideo/${videoId}`);
    if (response.status !== 200) throw new Error('Failed to delete video');
    return response.data;
  } catch (error) {
    console.error('Error delete video:', error);
    throw error;
  }
};

// get video progress by ID
export const getVideoProgress = async (videoId, retryCount = 3) => {
  let attempt = 0;
  
  while (attempt < retryCount) {
    try {
      const response = await axios.get(`/videos/get-video-progress/${videoId}`);
      if (response.status !== 200) throw new Error('Failed to get video progress');
      return response.data;
    } catch (error) {
      attempt++;
      if (attempt >= retryCount) {
        console.error('Error getting video progress after retries:', error);
        throw error;
      }
      console.warn(`Retry attempt ${attempt} after error: ${error.message}`);
      await new Promise(resolve => setTimeout(resolve, 5000)); // Retry after 5 seconds
    }
  }
};

// Process video by ID
export const processVideo = async (videoId) => {
  try {
    const response = await axios.post(`/process-video/${videoId}`);
    if (response.status !== 200) throw new Error('Failed to process video');
    return response.data;
  } catch (error) {
    console.error('Error processing video:', error);
    throw error;
  }
};

// Poll for video processing progress
export const pollVideoProgress = async (progressUrl) => {
  try {
    const response = await axios.get(progressUrl);
    return response.data;
  } catch (error) {
    console.error('Error fetching progress:', error);
    throw error;
  }
};

// Update hashtags for a video
export const updateVideoHashtags = async (videoId, hashtags) => {
  try {
    const response = await axios.put(`/videos/update-video-hashtags/${videoId}`, { hashtags });
    if (response.status !== 200) throw new Error('Failed to update hashtags');
    return response.data;
  } catch (error) {
    console.error('Error updating hashtags:', error);
    throw error;
  }
};