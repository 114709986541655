import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FaSignOutAlt } from 'react-icons/fa';
import GroupMemberList from '../GroupMemberList/GroupMemberList';

const DEFAULT_GROUP_PICTURE = 'https://via.placeholder.com/150'; // Placeholder image for the group

const FriendGroup = ({
  group,
  user,
  handleAddFriendToGroup,
  handleRemoveFriendFromGroup,
  handleMakeAdmin,
  handleLeaveGroup,
}) => {
  const isAdmin = group.admins.some((admin) => admin.email === user.email);
  const isMember = group.members.some((member) => member.email === user.email);
  const groupPicture = group.picture || DEFAULT_GROUP_PICTURE;

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex align-items-center">
          <img
            src={groupPicture}
            alt={`${group.name}'s group picture`}
            className="rounded-circle"
            width="50"
            height="50"
            style={{ marginRight: '10px' }}
          />
          <div>
            <Card.Title>{group.name}</Card.Title>
            <GroupMemberList
                group={group}
                user={user}
                isAdmin={isAdmin}
                handleRemoveFriendFromGroup={handleRemoveFriendFromGroup}
                handleMakeAdmin={handleMakeAdmin}
                handleAddFriendToGroup={handleAddFriendToGroup}
                />
          </div>
        </div>
          {isMember && (
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleLeaveGroup(group.id)}
              >
                <FaSignOutAlt className="me-2" />
                Leave
              </Button>
          )}
      </Card.Body>
    </Card>
  );
};

export default FriendGroup;
