import React, { useState, useEffect, useRef, useCallback } from 'react';
import interact from 'interactjs';
import { FaTrash, FaPlus, FaPlay, FaPause, FaTimes, FaCogs } from 'react-icons/fa';
import { cutVideo as cutVideoAPI } from '../../services/apiService';
import './CustomTimeBar.css';
import { formatTime, formatTimeToHHMMSS} from '../../services/utilityService'

function CustomTimeBar({
    currentTime,
    duration,
    onPlayPause,
    playing,
    setVideoTime,
    SubClips,
    video,
    onRequestClose,
    session_id
}) {
    const [subClips, setSubClips] = useState(SubClips);
    const [selectedSubClip, setSelectedSubClip] = useState(null);
    const playheadRef = useRef(null);
    const subClipRefs = useRef([]);
    const [thumbnails, setThumbnails] = useState([]);
    const [thumbnailWidth, setThumbnailWidth] = useState(0);
    const [thumbnailsData, setThumbnailsData] = useState({ thumbnails: [], thumbnailWidth: 200, thumbnailHeight: 20 });
    const [progressBarDimensions, setProgressBarDimensions] = useState(null);
    const progressBarRef = useRef(null);

    useEffect(() => {
        const updateDimensions = () => {
            if (progressBarRef.current) {
                setProgressBarDimensions({
                    width: progressBarRef.current.offsetWidth,
                    height: progressBarRef.current.offsetHeight,
                });
            }
        };
    
        window.addEventListener('resize', updateDimensions);
    
        // Update dimensions when the component mounts
        updateDimensions();

        console.log("updated dimensions")
    
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        console.log("enter thumbnails")
        if (!video || !duration || !progressBarRef.current) return;
    
        const videoSrc = video.url;
        const progressBarWidth = progressBarRef.current.offsetWidth;
    
        // Desired minimum thumbnail width
        const desiredThumbnailWidth = 50; // Adjust as needed
    
        // Calculate the number of thumbnails as an integer
        const numThumbnails = Math.floor(progressBarWidth / desiredThumbnailWidth);
    
        // Recalculate thumbnail width so that thumbnails fill the progress bar completely
        const thumbnailWidth = progressBarWidth / numThumbnails;
        console.log("call generateThumbnails")
        generateThumbnails(videoSrc, duration, numThumbnails, thumbnailWidth)
            .then((generatedThumbnails) => {
                setThumbnails(generatedThumbnails);
                setThumbnailWidth(thumbnailWidth);
            })
            .catch((error) => {
                console.error('Error generating thumbnails:', error);
            });
        console.log("generated Thumbnails")
    }, [video, duration, progressBarDimensions]);

    useEffect(() => {
        setSubClips(SubClips);
    }, [SubClips]);

    const dragMoveListener = useCallback((event) => {
        let target = event.target;
        let x = (parseFloat(target.dataset.x) || 0) + event.dx;
        let rect = target.parentNode.getBoundingClientRect();
        let startX = (parseFloat(target.style.left) / 100) * rect.width;
        let endX = ((parseFloat(target.style.left) + parseFloat(target.style.width)) / 100) * rect.width;
        
        // Ensure x does not move beyond the right edge
        x = Math.min(rect.width - endX, x);
        // Ensure x does not move beyond the left edge
        x = Math.max(-startX, x);
        
        // Calculate new Start and End Times
        let startTime = ((x + startX) / rect.width) * duration;
        let endTime = ((x + endX) / rect.width) * duration;

        // Transform target
        target.style.transform = `translate(${x}px, 0)`;
        target.dataset.x = x;

        // Update timestamp directly
        const timestampElement = target.querySelector('.timestamp');
        if (timestampElement) {
            timestampElement.innerHTML = `${formatTime(startTime)} - ${formatTime(endTime)}`;
        }
    }, [duration]);

    const dragMoveEndListener = useCallback((event) => {
        const target = event.target;
        const x = (parseFloat(target.dataset.x) || 0) + event.dx;
        const index = parseInt(target.dataset.index, 10);
        const rect = target.parentNode.getBoundingClientRect();
        const subClip = subClips[index];
        const startX = (parseFloat(target.style.left) / 100) * rect.width;
        const endX = ((parseFloat(target.style.left) + parseFloat(target.style.width)) / 100) * rect.width;
        
        // Calculate new Start and End Times
        subClip.startTime = ((x + startX) / rect.width) * duration;
        subClip.endTime = ((x + endX) / rect.width) * duration;

        // Reset Transform
        target.style.transform = `translate(0, 0)`;
        target.style.left = `${((x + startX) / rect.width) * 100}%`;
        target.dataset.x = 0;
    }, [subClips, duration]);

    const dragPlayheadListener = useCallback((event) => {
        const target = event.target;
        const x = (parseFloat(target.dataset.x) || 0) + event.dx;
        target.style.transform = `translate(${x}px, 0)`;
        target.dataset.x = x;
    }, []);

    const dragPlayheadEndListener = useCallback((event) => {
        const target = event.target;
        const rect = target.parentNode.getBoundingClientRect();
        const x = (parseFloat(target.dataset.x) || 0) + event.dx;
        const transform_percent = (x / rect.width) * 100;

        // Reset Transform
        target.style.transform = `translate(0, 0)`;
        target.dataset.x = 0;

        const newTime = ((parseFloat(target.style.left) + transform_percent) / 100) * duration;
        setVideoTime(newTime); // Ensure newTime is used for setting the video time
    }, [duration, setVideoTime]);

    const resizeMoveListener = useCallback((event) => {
        const target = event.target;
        const index = parseInt(target.dataset.index, 10);
        const subClip = subClips[index];
        const rect = target.parentNode.getBoundingClientRect();
        let x = (parseFloat(target.dataset.x) || 0);
        const startX = (parseFloat(target.style.left) / 100) * rect.width;
        const endX = (subClip.endTime / duration) * rect.width;

        let startTime = subClip.startTime;
        let endTime = subClip.endTime;

        if (event.edges.left) {
            x += event.deltaRect.left;
            x = Math.max(-startX, x);
            x = Math.min(endX - startX, x);
            startTime = ((x + startX) / rect.width) * duration;
            target.style.width = `${((endX - startX - x) / rect.width) * 100}%`;
            target.style.transform = `translate(${x}px, 0)`;
        } else if (event.edges.right) {
            x += event.deltaRect.right;
            x = Math.max(startX - endX, x);
            x = Math.min(rect.width - endX, x);
            endTime = ((x + endX) / rect.width) * duration;
            target.style.width = `${((x + endX - startX) / rect.width) * 100}%`;
        }

        target.dataset.x = x;

        const timestampElement = target.querySelector('.timestamp');
        if (timestampElement) {
            timestampElement.innerHTML = `${formatTime(startTime)} - ${formatTime(endTime)}`;
        }
    }, [subClips, duration]);

    const resizeEndListener = useCallback((event) => {
        const target = event.target;
        const index = parseInt(target.dataset.index, 10);
        const rect = target.parentNode.getBoundingClientRect();
        const subClip = subClips[index];
        const startX = (parseFloat(target.style.left) / 100) * rect.width;
        const endX = (subClip.endTime / duration) * rect.width;
        let x = parseFloat(target.dataset.x) || 0;

        if (event.edges.left) {
            subClip.startTime = ((x + startX) / rect.width) * duration;
            target.style.left = `${((x + startX) / rect.width) * 100}%`;
            setVideoTime(subClip.startTime);
        } else if (event.edges.right) {
            subClip.endTime = ((x + endX) / rect.width) * duration;
            setVideoTime(subClip.endTime);
        }

        target.style.transform = `translate(0, 0)`;
        target.dataset.x = 0;
    }, [subClips, duration, setVideoTime]);

    useEffect(() => {
        interact('.subclip').draggable({
            onmove: dragMoveListener,
            onend: dragMoveEndListener,
        }).resizable({
            edges: { left: '.resize-handle.left', right: '.resize-handle.right' },
            onmove: resizeMoveListener,
            onend: resizeEndListener
        });

        interact('.playhead').draggable({
            restrict: {
                restriction: "parent",
                endOnly: true,
                elementRect: { top: 0, left: 0, bottom: 1, right: 1 }
            },
            onmove: dragPlayheadListener,
            onend: dragPlayheadEndListener
        });

        return () => {
            interact('.subclip').unset();
            interact('.playhead').unset();
        };
    }, [
        dragMoveListener,
        dragMoveEndListener,
        resizeMoveListener,
        resizeEndListener,
        dragPlayheadListener,
        dragPlayheadEndListener
    ]);



    async function cutVideo() {
        if (!subClips || subClips.length === 0) {
            return;
        }

        const cuts = subClips.map((clip) => ({
            start: formatTimeToHHMMSS(clip.startTime),
            end: formatTimeToHHMMSS(clip.endTime)
        }));

        const videoId = video.id;

        try {
            await cutVideoAPI(videoId, cuts, session_id); // Use the API function from apiService
        } catch (error) {
            console.error("Error cutting video:", error);
        }
    }

    const handleDelete = (index) => {
        const updatedSubClips = subClips.filter((_, i) => i !== index);
        setSubClips(updatedSubClips);
        setSelectedSubClip(null);
    };

    const handleAddSubClip = (position) => {
        const newSubClip = {
            startTime: position * duration,
            endTime: position * duration + 10,
        };
        const updatedSubClips = [...subClips, newSubClip];
        setSubClips(updatedSubClips);
    };

    const handleProgressBarClick = (event) => {
        if (event.target.classList.contains('playhead')) return;

        const rect = event.target.getBoundingClientRect();
        const clickedPosition = (event.clientX - rect.left) / rect.width;
        const newTime = clickedPosition * duration;
        setVideoTime(newTime);
    };

    const addSubClipAtPlayhead = () => {
        const playheadTime = (parseFloat(playheadRef.current.style.left) / 100) * duration;
        handleAddSubClip(playheadTime / duration);
    };

    function generateThumbnails(videoSrc, duration, numThumbnails, thumbnailWidth) {
        return new Promise((resolve, reject) => {
            if (!videoSrc) {
                reject(new Error('Video source URL is undefined.'));
                return;
            }
    
            const thumbnails = [];
    
            // Create the video element
            const video = document.createElement('video');
    
            // Set video attributes
            video.src = videoSrc;
            video.crossOrigin = 'anonymous';
            video.muted = true; // Allow autoplay on mobile devices
            video.autoplay = true;
            video.playsInline = true;
            video.setAttribute('playsinline', '');
            video.setAttribute('webkit-playsinline', '');
            video.controls = false;
    
            // Hide the video element
            video.style.position = 'absolute';
            video.style.width = '1px';
            video.style.height = '1px';
            video.style.left = '0';
            video.style.top = '0';
            video.style.opacity = '0';
            video.style.pointerEvents = 'none';
            video.style.visibility = 'hidden';
    
            // Append the video to the body
            document.body.appendChild(video);
    
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            // Set canvas dimensions to create square thumbnails
            const thumbnailDimension = thumbnailWidth; // Square dimensions
            canvas.width = thumbnailDimension;
            canvas.height = thumbnailDimension;
    
            const handleCanPlay = () => {
                video.removeEventListener('canplay', handleCanPlay);
    
                const videoWidth = video.videoWidth;
                const videoHeight = video.videoHeight;
    
                const interval = duration / numThumbnails;
                let currentTime = 0;
    
                const captureThumbnail = () => {
                    // Calculate cropping coordinates
                    let sx, sy, sWidth, sHeight;
    
                    if (videoWidth > videoHeight) {
                        // Landscape
                        sHeight = videoHeight;
                        sWidth = videoHeight;
                        sx = (videoWidth - sWidth) / 2;
                        sy = 0;
                    } else {
                        // Portrait or Square
                        sWidth = videoWidth;
                        sHeight = videoWidth;
                        sx = 0;
                        sy = (videoHeight - sHeight) / 2;
                    }
    
                    // Delay drawing to ensure the frame is ready
                    setTimeout(() => {
                        ctx.drawImage(
                            video,
                            sx,
                            sy,
                            sWidth,
                            sHeight,
                            0,
                            0,
                            thumbnailDimension,
                            thumbnailDimension
                        );
    
                        const dataURL = canvas.toDataURL('image/png');
                        thumbnails.push(dataURL);
    
                        if (thumbnails.length < numThumbnails) {
                            currentTime += interval;
                            video.currentTime = currentTime;
                        } else {
                            video.removeEventListener('seeked', captureThumbnail);
                            // Clean up
                            document.body.removeChild(video);
                            resolve(thumbnails);
                        }
                    }, 100); // Adjust delay as necessary
                };
    
                video.addEventListener('seeked', captureThumbnail);
    
                // Start capturing
                video.currentTime = currentTime;
            };
    
            // Handle video errors
            video.addEventListener('error', (e) => {
                console.error('Error loading video:', e);
                // Clean up on error
                document.body.removeChild(video);
                reject(e);
            });
    
            // Start the process after the video can play
            video.addEventListener('canplay', handleCanPlay);
    
            // Attempt to play and pause the video to enable seeking
            const playPromise = video.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(() => {
                        video.pause();
                    })
                    .catch((error) => {
                        console.warn('Autoplay prevented, proceeding without play:', error);
                        // Proceed even if autoplay is prevented
                    });
            }
        });
    }
    

    return (
        <div className="custom-time-bar">
            <div className="buttons-container">
                <div className="left-buttons">
                    <button id="playPauseButton" onClick={onPlayPause}>
                        {playing ? <FaPause /> : <FaPlay />}
                    </button>
                    <button id="cutVideoButton" type="button" onClick={cutVideo}>
                        <FaCogs />
                    </button>
                    <button id="addSubClipButton" type="button" onClick={addSubClipAtPlayhead}>
                        <FaPlus />
                    </button>
                    {selectedSubClip !== null && (
                        <button className="delete-button" onClick={() => handleDelete(selectedSubClip)}>
                            <FaTrash />
                        </button>
                    )}
                </div>
                <div className="right-buttons">
                    <button id="closeEditorButton" type="button" onClick={onRequestClose}>
                        <FaTimes />
                    </button>
                </div>
            </div>

            <div className="progress-bar" ref={progressBarRef} onClick={handleProgressBarClick}>
                {/* Thumbnail Images */}
                <div className="thumbnail-container">
                    {thumbnails.map((thumbnail, index) => (
                        <img
                            key={index}
                            src={thumbnail}
                            alt={`Thumbnail ${index}`}
                            className="thumbnail-image"
                            style={{
                                width: `${thumbnailWidth}px`,
                                height: `${progressBarRef.current.offsetHeight}px`,
                            }}
                        />
                    ))}
                </div>
                {subClips.map((subClip, index) => (
                    <div
                        key={index}
                        className={`subclip ${selectedSubClip === index ? 'selected' : ''}`}
                        style={{ left: `${(subClip.startTime / duration) * 100}%`, width: `${((subClip.endTime - subClip.startTime) / duration) * 100}%` }}
                        data-index={index}
                        onClick={(e) => { e.stopPropagation(); setSelectedSubClip(index); }}
                        ref={el => subClipRefs.current[index] = el}
                    >
                        <div className="resize-handle left"></div>
                        <div className="resize-handle right"></div>
                        <div className="timestamp">{formatTime(subClip.startTime)} - {formatTime(subClip.endTime)}</div>
                    </div>
                ))}
                <div
                    ref={playheadRef}
                    className="playhead"
                    style={{ left: `${(currentTime / duration) * 100}%` }}
                    data-x="0"
                    onClick={(e) => e.stopPropagation()}
                ></div>
            </div>
        </div>
    );
}

export default CustomTimeBar;
