import React from 'react';
import { Row, Col } from 'react-bootstrap';
import FriendItem from '../FriendItem/FriendItem'; // Import your FriendItem component

const FriendList = ({ friends, showFriends, onAddFriend, onRemoveFriend, onAcceptFriendRequest, onRejectFriendRequest }) => {
  if (!showFriends) return null;

  return (
    <Row>
      {friends.map(friend => (
        <Col md={6} key={friend.id || friend.email}>
          <FriendItem
            friend={friend}
            onRemoveFriend={onRemoveFriend}
            onAddFriend={onAddFriend}
            onAcceptFriendRequest={onAcceptFriendRequest} // Pass accept friend request function
            onRejectFriendRequest={onRejectFriendRequest} // Pass reject friend request function
          />
        </Col>
      ))}
    </Row>
  );
};

export default FriendList;
