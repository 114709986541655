import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { FaTrash, FaUserPlus } from 'react-icons/fa';

const DEFAULT_PROFILE_PICTURE = 'https://via.placeholder.com/150';  // Use placeholder if needed

const FriendItem = ({ friend, onRemoveFriend, onAddFriend, onAcceptFriendRequest, onRejectFriendRequest }) => {
  const profilePicture = friend.profile_picture || DEFAULT_PROFILE_PICTURE;

  return (
    <Card className="mb-3">
      <Card.Body>
        <div className="d-flex align-items-center">
          <img
            src={profilePicture}
            alt={`${friend.nickname || friend.email}'s profile`}
            className="rounded-circle"
            width="50"
            height="50"
            style={{ marginRight: '10px' }}
          />
          <div>
            <Card.Title>{friend.nickname || friend.email}</Card.Title>
            <Card.Text>Status: {friend.status}</Card.Text>
          </div>
        </div>
        
        <div className="mt-2">
          {friend.status === 'incoming request' && (
            <>
              <Button
                variant="success"
                size="sm"
                onClick={() => onAcceptFriendRequest(friend.id)}
                className="me-2"
              >
                Accept
              </Button>
              <Button
                variant="danger"
                size="sm"
                onClick={() => onRejectFriendRequest(friend.id)}
                className="me-2"
              >
                Reject
              </Button>
            </>
          )}
          {friend.status === 'accepted' && (
            <Button
              variant="danger"
              size="sm"
              onClick={() => onRemoveFriend(friend.id)}
            >
              <FaTrash className="me-2" /> Remove
            </Button>
          )}
          {friend.status === 'new' && (
            <Button
              variant="primary"
              size="sm"
              onClick={() => onAddFriend(friend.email)}
            >
              <FaUserPlus className="me-2" /> Add
            </Button>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default FriendItem;
