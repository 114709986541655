import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FaUserPlus } from 'react-icons/fa';

const CreateGroupForm = ({ createGroup }) => {
  const [newGroupName, setNewGroupName] = useState('');

  const handleCreateGroup = () => {
    createGroup(newGroupName);
    setNewGroupName('');
  };

  return (
    <>
      <h3>Create Friend Group</h3>
      <Form.Control
        type="text"
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
        placeholder="Enter group name"
        className="mb-2"
      />
      <Button onClick={handleCreateGroup} variant="primary" block>
        <FaUserPlus className="me-2" /> Create Group
      </Button>
    </>
  );
};

export default CreateGroupForm;
