import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ToastContainer } from 'react-toastify';
import { Container, Row, Col } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FriendsPage.css';
import useFriendsData from '../../hooks/useFriendsData';
import FilterBar from '../../components/FilterBar/FilterBar';
import FriendList from '../../components/FriendList/FriendList';
import GroupList from '../../components/GroupList/GroupList';
import AddFriendForm from '../../components/AddFriendForm/AddFriendForm';
import CreateGroupForm from '../../components/CreateGroupForm/CreateGroupForm';

const FriendsPage = ({ user }) => {
  const { isAuthenticated } = useAuth0();
  const {
    friends,
    friendGroups,
    filteredFriends,
    filteredGroups,
    searchQuery,
    setSearchQuery,
    filter,
    setFilter,
    addFriend,
    createGroup,
    handleAddFriendToGroup,
    handleRemoveFriendFromGroup,
    handleMakeAdmin,
    handleRemoveFriend,
    handleLeaveGroup,
    acceptFriendRequest,
    rejectFriendRequest
  } = useFriendsData();

  return (
    <Container>
      <ToastContainer />
      <h2 className="my-4">Friends Page</h2>

      <FilterBar filter={filter} setFilter={setFilter} searchQuery={searchQuery} setSearchQuery={setSearchQuery} />

      <Row>
        <FriendList
          friends={filteredFriends}
          showFriends={filter.value === 'both' || filter.value === 'friends'}
          onAddFriend={addFriend}
          onRemoveFriend={handleRemoveFriend}
          onAcceptFriendRequest={acceptFriendRequest}
          onRejectFriendRequest={rejectFriendRequest} // Pass rejectFriendRequest to FriendList
        />
        <GroupList
          groups={filteredGroups}
          user={user}
          showGroups={filter.value === 'both' || filter.value === 'groups'}
          handleAddFriendToGroup={handleAddFriendToGroup}
          handleRemoveFriendFromGroup={handleRemoveFriendFromGroup}
          handleMakeAdmin={handleMakeAdmin}
          handleLeaveGroup={handleLeaveGroup}
        />
      </Row>

      {isAuthenticated && (
        <Row className="mt-4">
          <Col md={6} className="mb-3">
            <CreateGroupForm createGroup={createGroup} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FriendsPage;
