import React from 'react';
import Select from 'react-select';

const FilterBar = ({ filter, setFilter, searchQuery, setSearchQuery }) => {
  const filterOptions = [
    { value: 'both', label: 'Show Both' },
    { value: 'friends', label: 'Show Friends' },
    { value: 'groups', label: 'Show Groups' }
  ];

  return (
    <div className="controls">
      <Select value={filter} onChange={setFilter} options={filterOptions} />
      <div className="search-input">
        <input
          type="text"
          placeholder="Search by name or email or ID"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>
    </div>
  );
};

export default FilterBar;
