import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from '../services/axios';
import { toast } from 'react-toastify';

const useFriendsData = () => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [friends, setFriends] = useState([]);
  const [friendGroups, setFriendGroups] = useState([]);
  const [filteredFriends, setFilteredFriends] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filter, setFilter] = useState({ value: 'both', label: 'Show Both' });
  const [searchedUsers, setSearchedUsers] = useState([]);

  useEffect(() => {
    if (getAccessTokenSilently) {
      fetchFriends();
      fetchFriendGroups();
    }
  }, [getAccessTokenSilently]);

  const fetchFriends = async () => {
    try {
      const token = await getAccessTokenSilently();
      if (!token) throw new Error('No access token');
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get('/friends/friends', { headers });
      setFriends(response.data.friends);
    } catch (error) {
      console.error('Error fetching friends:', error);
      toast.error('Failed to fetch friends.');
    }
  };

  // Function to search for a user by their ID, email, or nickname
  const searchUsers = async (query) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get(`/friends/friend-details?search=${query}`, { headers });
      
      if (response.status === 200){
        console.log("Found a friend")
        const existingFriend = friends.find((friend) => friend.email === response.data.email);
        if (!existingFriend) {
          setFilteredFriends([response.data]); // Store user data found by search
        } else {
          setFilteredFriends([existingFriend]); // Clear search results if the user is already a friend
        }
      }
      
    } catch (error) {
      //toast.error('Failed to search users.');
    }
  };

  const fetchFriendGroups = async () => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      const response = await axios.get('/friends/friend-groups', { headers });
      setFriendGroups(response.data.groups);
    } catch (error) {
      toast.error('Failed to fetch friend groups.');
    }
  };

  const addFriend = async (query) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      console.log(query)
      await axios.post('/friends/add-friend', { query }, { headers });
      fetchFriends();
      toast.success('Friend added successfully.');
    } catch (error) {
      toast.error('Failed to add friend.');
    }
  };

  const createGroup = async (name) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post('/friends/create-friend-group', { name }, { headers });
      fetchFriendGroups();
      toast.success('Group created successfully.');
    } catch (error) {
      toast.error('Failed to create group.');
    }
  };

  const handleRemoveFriend = async (friendId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.delete(`/friends/friends/${friendId}`, { headers });
      setFriends((prevFriends) => prevFriends.filter(friend => friend.id !== friendId));
      toast.success('Friend removed successfully.');
    } catch (error) {
      console.error('Failed to remove friend:', error);
      toast.error('Failed to remove friend.');
    }
  };

  const acceptFriendRequest = async (friendId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      await axios.post(`/friends/friend-requests/${friendId}/accept`, {}, { headers });

      setFriends(prevFriends =>
        prevFriends.map(friend =>
          friend.id === friendId ? { ...friend, status: 'accepted' } : friend
        )
      );

      toast.success('Friend request accepted successfully.');
    } catch (error) {
      console.error('Failed to accept friend request:', error);
      toast.error('Failed to accept friend request.');
    }
  };

  const rejectFriendRequest = async (friendId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      await axios.post(`/friends/friend-requests/${friendId}/reject`, {}, { headers });

      setFriends(prevFriends => prevFriends.filter(friend => friend.id !== friendId));

      toast.success('Friend request rejected successfully.');
    } catch (error) {
      console.error('Failed to reject friend request:', error);
      toast.error('Failed to reject friend request.');
    }
  };

  // add a friend to a group
  const handleAddFriendToGroup = async (groupId, email) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post(`/friends/friend-groups/${groupId}/add-friend`, { email }, { headers });
      fetchFriendGroups();
      toast.success('Friend added to group successfully.');
    } catch (error) {
      toast.error('Failed to add friend to group.');
    }
  };

  // remove a friend from a group
  const handleRemoveFriendFromGroup = async (groupId, friendId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.delete(`/friends/friend-groups/${groupId}/remove-friend/${friendId}`, { headers });
      fetchFriendGroups();
      toast.success('Friend removed from group successfully.');
    } catch (error) {
      toast.error('Failed to remove friend from group.');
    }
  };

  // promote a friend to admin in a group
  const handleMakeAdmin = async (groupId, friendId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      await axios.post(`/friends/friend-groups/${groupId}/make-admin/${friendId}`, {}, { headers });
      fetchFriendGroups();
      toast.success('Friend promoted to admin successfully.');
    } catch (error) {
      toast.error('Failed to promote friend to admin.');
    }
  };

  const handleLeaveGroup = async (groupId) => {
    try {
      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };
      
      await axios.delete(`/friends/friend-groups/${groupId}/remove-friend/${user.sub}`, { headers });
      
      fetchFriendGroups();  // Refresh the group list after leaving
      toast.success('You have left the group.');
    } catch (error) {
      toast.error('Failed to leave the group.');
    }
  };
  

  const filterAndSortFriendsGroups = () => {
    const filteredFriendsList = friends.filter(friend =>
      friend.email.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredFriends(filteredFriendsList);

    const filteredGroupsList = friendGroups.filter(group =>
      group.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredGroups(filteredGroupsList);
  };

  useEffect(() => {
    searchUsers(searchQuery);
  }, [searchQuery, friends]);

  useEffect(() => {
    filterAndSortFriendsGroups();
  }, [friends, friendGroups, searchQuery, filter]);

  return {
    friends,
    friendGroups,
    filteredFriends,
    filteredGroups,
    searchedUsers,
    searchQuery,
    setSearchQuery,
    filter,
    setFilter,
    addFriend,
    createGroup,
    handleRemoveFriend,
    acceptFriendRequest,
    rejectFriendRequest,
    handleAddFriendToGroup,
    handleRemoveFriendFromGroup,
    handleMakeAdmin,
    handleLeaveGroup,
  };
};

export default useFriendsData;
