import React from 'react';
import { Accordion, Button, Form } from 'react-bootstrap';
import { FaTrash, FaUserShield } from 'react-icons/fa';

const DEFAULT_PROFILE_PICTURE = 'https://via.placeholder.com/150'; // Default placeholder for member profile

const GroupMemberList = ({
  group,
  user,
  isAdmin,
  handleRemoveFriendFromGroup,
  handleMakeAdmin,
  handleAddFriendToGroup,
}) => {
  const [groupEmail, setGroupEmail] = React.useState('');

  return (
    <Accordion defaultActiveKey={null} className="mt-2"> {/* Accordion will now be collapsed by default */}
      <Accordion.Item eventKey="0">
        <Accordion.Header>Members</Accordion.Header>
        <Accordion.Body>
          <ul className="list-unstyled">
            {group.members.map((member) => (
              <li
                key={member.id}
                className="d-flex align-items-center justify-content-between mb-2"
              >
                <div className="d-flex align-items-center">
                  <img
                    src={member.profile_picture || DEFAULT_PROFILE_PICTURE}
                    alt={`${member.email}'s profile`}
                    className="rounded-circle"
                    width="40"
                    height="40"
                    style={{ marginRight: '10px' }}
                  />
                  <span>{member.nickname || member.email}</span>
                </div>
                {isAdmin && (
                  <div>
                    <Button
                      variant="danger"
                      size="sm"
                      className="ms-2"
                      onClick={() => handleRemoveFriendFromGroup(group.id, member.id)}
                    >
                      <FaTrash />
                    </Button>
                    {!group.admins.some((admin) => admin.email === member.email) && (
                      <Button
                        variant="secondary"
                        size="sm"
                        className="ms-2"
                        onClick={() => handleMakeAdmin(group.id, member.id)}
                      >
                        <FaUserShield />
                      </Button>
                    )}
                  </div>
                )}
              </li>
            ))}
          </ul>
          {isAdmin && (
            <>
              <Form.Control
                type="email"
                value={groupEmail}
                onChange={(e) => setGroupEmail(e.target.value)}
                placeholder="Enter friend's email"
                className="mb-2"
              />
              <Button
                onClick={() => handleAddFriendToGroup(group.id, groupEmail)}
                variant="primary"
                block
              >
                Add Friend to Group
              </Button>
            </>
          )}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default GroupMemberList;
